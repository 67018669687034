module.exports = `
<!DOCTYPE html>
<html>

<head>
    <meta name="robots" content="noindex">
    <title></title>
</head>
<style>
    body {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -o-user-select: none;
        background-color: #ededed;
    }

    h2 {
        color: #535353;
    }

    li {
        color: #272727;
        margin-top: 4px;
    }

    .card {
        background-color: #ffffff;
        margin-top: 25px;
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0px 1px 3px #9c9c9c;
        border-radius: 2px;
    }

    .last-card {
        margin-bottom: 25px;
    }
</style>

<body>
    <div class="card">
        <h2 class="title">Version 1.0.17.3</h2>
        <ul>
            <li>Add recommended m3u8 formats</li>
            <li>Fix the issue of YouTube video titles containing emojis causing merge failures</li>
            <li>Fix the issue of YouTube webm format video merge failures</li>
            <li>Fix the issue of merge failures when downloading m3u8 videos from certain websites</li>
            <li>Fixed various crashes and bugs</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.17</h2>
        <ul>
            <li>Added hard-coded subtitle files, Settings -> Downloads -> Use hard subtitle format</li>
            <li>Added support for AV1 format audio and video merging</li>
            <li>Add automatic URL extraction in search and a quick toggle switch in the notification bar</li>
            <li>Added sniffing of multiple video formats</li>
            <li>Added sniffing of video covers and detailed information</li>
            <li>Fixed Error in merging m3u8</li>
            <li>Fixed various crashes and bugs</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.16</h2>
        <ul>
            <li>Fix YouTube homepage navigation and lists</li>
            <li>Fix likes, favorites, and additional videos in YouTube video details</li>
            <li>Fix YouTube search page and user details page</li>
            <li>Add support for multi-audio track playback and downloading on YouTube</li>
            <li>Fix various crashes and bugs in the application</li>
            <li>Fix Douyin, IXiGua, Naver, VK, Bilibili, and other websites</li>
            <li>Add support for Telegram, Yandex, Rutube and other websites</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.12.1 ~ 1.0.15.3</h2>
        <ul>
            <li>Fixed YouTube slow download</li>
            <li>Fixed YouTube captcha</li>
            <li>Fixed YouTube search and user detail page</li>
            <li>Fixed YouTube comment and likes</li>
            <li>Fixed Android 10 downloaded song not show</li>
            <li>Fixed many extractor errors(Bilibi, Vlive...)</li>
        </ul>
    </div>
   <div class="card">
        <h2 class="title">Version 1.0.10, 1.0.11</h2>
        <ul>
            <li>Fix the problem that some youtube videos cannot be downloaded </li>
            <li>Fix search error</li>
            <li>Fix home tabs fetch error</li>
            <li>Support some new websites, such as Avgel, Naver...</li>
            <li>Refactoring code</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.8, 1.0.9</h2>
        <ul>
            <li>Fix download HD webm format Video conversion error</li>
            <li>Fix error sharing file</li>
            <li>Fix upgrade apk error</li>
            <li>Fix crash</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.7</h2>
        <ul>
            <li>Added automatic sniffing video function</li>
            <li>Fix crash when selecting multiple videos</li>
            <li>Fix YouTube subtitle display</li>
            <li>Fix the login interface and video playback interface are disordered</li>
            <li>Fix logout error</li>
            <li>Fix switching languages</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 1.0.6</h2>
        <ul>
            <li>Add Dash Format support</li>
            <li>Support watch live online</li>
            <li>Fix clear browser history</li>
            <li>Fix download mp3 crash</li>
        </ul>
    </div>

    <div class="card">
        <h2 class="title">Version 1.0.5</h2>
        <ul>
            <li>Fix download mp3 crash</li>
            <li>Bug fixes</li>
        </ul>
    </div>

    <div class="card">
        <h2 class="title">Version 1.0.4</h2>
        <ul>
            <li>Add Dash Format support</li>
            <li>Support watch live online</li>
            <li>Fix clear browser history</li>
            <li>Bug fixes</li>
        </ul>
    </div>
    
    <div class="card">
        <h2 class="title">Version 1.0.0</h2>
        <ul>
            <li>Add bookmarks</li>
            <li>Subtitles Downloading is here. You can either download subtitle files separately or merge subtitles in the video itself</li>
            <li>Subtitles playback in video detail screen with styling support</li>
            <li>Double tap seek 10 seconds</li>
            <li>Storyboard preview while seeking videos, now you see a preview image when you seek a video which helps you to seek more precisely</li>
            <li>Support multipart files download and merge</li>
            <li>Bug fixes</li>
        </ul>
    </div>

    <div class="card">
        <h2 class="title">Version 0.0.5</h2>
        <ul>
            <li>Playback speed control</li>
            <li>Double tap seek 10 seconds</li>
            <li>An all black night mode in the app</li>
            <li>Musically added</li>
            <li>Crunchyroll added</li>
            <li>One Other** site added</li>
            <li>Preference for default playback resolution</li>
            <li>Bug fixes</li>
        </ul>
    </div>
   
    <div class="card">
        <h2 class="title">Version 0.0.3</h2>
        <ul>
            <li>UI updated with the latest material design.</li>
            <li>Select multiple videos and download them in one go.</li>
            <li>Quick download option which lets you download anything in a second.</li>
            <li>Age restriction bug solved. (Sign in to watch and download restricted videos)</li>
            <li>Restricted mode (Safe mode) added</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 0.0.2</h2>
        <ul>
            <li>Download videos upto 1080p (full HD) resolutions.</li>
            <li>Download unlimited music (mp3 also available)</li>
            <li>A much enhanced user interface.</li>
            <li>More detailed settings screen.</li>
            <li>Auto correct misspelled searches.</li>
        </ul>
    </div>
    <div class="card">
        <h2 class="title">Version 0.0.2</h2>
        <ul>
            <li>A new UI with a beautiful white theme.</li>
            <li>A very useful help section.</li>
            <li>Preview any video before downloading.</li>
        </ul>
    </div>
    <div class="card last-card">
        <h2 class="title">Version 0.0.1</h2>
        <ul>
            <li>Search videos from around the world</li>
            <li>Download unlimited Videos in HD, SD, LD definitions in various formats like flv, mp4 and webm</li>
            <li>Download any video in just two steps.</li>
            <li>Sleek interface and design.</li>
        </ul>
    </div>
</body>

</html>
`
